import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Select as AntdSelect } from "antd";

import { OperativeAppointmentData } from "~/utils/interfaces/Appointment";

interface OperativeFormProps {
  operativeData?: OperativeAppointmentData;
  setOperativeData: Dispatch<SetStateAction<OperativeAppointmentData | undefined>>;
  isOperativeFlu: boolean;
}

const OperativeForm = ({ operativeData, setOperativeData, isOperativeFlu }: OperativeFormProps): JSX.Element => {
  const formChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!operativeData) {
      return;
    }
    const newValue = event.target.value;
    const attribute = event.target.id;
    setOperativeData({ ...operativeData, [attribute]: newValue });
  };

  const leadChange = (event): void => {
    if (!operativeData) {
      return;
    }
    setOperativeData({ ...operativeData, lead: event.target.value });
  };

  return (
    <React.Fragment>
      {operativeData && (
        <>
          <h4>Datos operativo</h4>
          <div className="flex flex-row flex-nowrap items-center">
            <TextField
              id="name"
              onChange={formChange}
              value={operativeData.name}
              helperText="Nombre"
              fullWidth
              className="m-2"
            />
            <TextField
              id="base_price"
              onChange={formChange}
              value={operativeData.base_price}
              helperText={isOperativeFlu ? "Precio neto" : "Precio base"}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              className="m-2"
            />
          </div>
          {!isOperativeFlu && (
            <div className="flex flex-row flex-nowrap items-center">
              <TextField
                id="person_price"
                onChange={formChange}
                value={operativeData.person_price}
                helperText="Precio por persona"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                fullWidth
                className="m-2"
              />
              <FormControl
                fullWidth
                className="m-2"
              >
                <Select
                  onChange={leadChange}
                  value={operativeData.lead}
                  fullWidth
                >
                  <MenuItem value="formulario">Formulario</MenuItem>
                  <MenuItem value="partner">Partner</MenuItem>
                  <MenuItem value="intercom">Intercom</MenuItem>
                </Select>
                <FormHelperText>Lead</FormHelperText>
              </FormControl>
            </div>
          )}
          {isOperativeFlu && (
            <div className="flex flex-row flex-nowrap items-center">
              <TextField
                id="vaccine_amount"
                onChange={(event) => setOperativeData({
                  ...operativeData,
                  operative_flu: {
                    ...operativeData.operative_flu,
                    vaccine_amount: Number(event.target.value),
                  },
                })}
                value={operativeData.operative_flu?.vaccine_amount}
                helperText="Número de vacunas"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                fullWidth
                className="m-2"
              />
              <AntdSelect
                options={[
                  { value: "delivery", label: "Entrega" },
                  { value: "administration", label: "Vacunación" }
                ]}
                placeholder="Selecciona el modo"
                className="w-3/4"
                onChange={(value) => setOperativeData({
                  ...operativeData,
                  operative_flu: {
                    ...operativeData.operative_flu,
                    mode: value as "delivery" | "administration",
                  },
                })}
                value={operativeData.operative_flu?.mode}
              />
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default OperativeForm;
